export const colorObj = {
    Bone: "#FF4000",
    Cardiovascular: "#FDBD7D",
    Dermatological: "#F6C01E",
    "Ear, Nose, Throat": "#EBEB24",
    Endocrine: "#BDDC61",
    "Female genital organs": "#65C900",
    Gastrointestinal: "#77B463",
    Hematological: "#0E9B0E",
    "Hepatobiliary pancreas": "#00FF3F",
    Immunological: "#276546",
    Infectious: "#01503C",
    Joint: "#426C6C",
    "Male genital organs": "#096888",
    Metabolic: "#00FFFF",
    Muscular: "#3E5DBA",
    Neoplasm: "#0404CF",
    Neurological: "#A895E0",
    Nutritional: "#7C0AEE",
    Ophthalmological: "#CC45F8",
    Oral: "#FE49FE",
    Psychiatric: "#FF0AC2",
    Respiratory: "#FBAAD3",
    Spine: "#F40641",
    Urinary: "#E74C4C",
};
